import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { BrandVoiceActions } from './brand-voice.actions';
import { BrandVoice } from './brand-voice.model';

export const brandVoiceFeatureKey = 'brandvoice';

export interface BrandVoiceState extends EntityState<BrandVoice> {
    isLoading: boolean;
}

export const adapter: EntityAdapter<BrandVoice> = createEntityAdapter<BrandVoice>();

export const initialState: BrandVoiceState = adapter.getInitialState({
    isLoading: false,
});

export const brandVoiceReducer = createReducer(
    initialState,
    on(BrandVoiceActions.loadBrandVoices, state => ({ ...state, isLoading: true })),
    on(BrandVoiceActions.loadBrandVoicesSuccess, (state, { brandVoices }) =>
        adapter.upsertMany(brandVoices, { ...state, isLoading: false }),
    ),
    on(BrandVoiceActions.createBrandVoiceSuccess, BrandVoiceActions.updateBrandVoiceSuccess, (state, { brandVoice }) =>
        adapter.upsertOne(brandVoice, state),
    ),
    on(BrandVoiceActions.updateBrandVoiceSuccess, (state, { brandVoice }) => adapter.upsertOne(brandVoice, state)),
    on(BrandVoiceActions.deleteBrandVoiceById, (state, { id }) => adapter.removeOne(id, state)),
);

export const brandVoiceFeature = createFeature({
    name: brandVoiceFeatureKey,
    reducer: brandVoiceReducer,
    extraSelectors: ({ selectBrandvoiceState }) => ({
        ...adapter.getSelectors(selectBrandvoiceState),
    }),
});
